<template>
  <div class="pages-table">
    <CollectionsTable
      :collections-list="pagesList"
      group="wsPage"
      hide-column-members
      @click-action-menu="onClickActionMenu({
        ...$event,
        group: 'wsPage',
        actionsConditions: [{
          name: 'PageFollow',
          kind: ['default'],
        }],
        showActions: true,
        kind: 'default',
      })" />
    <Actions
      :show-activator="false"
      :item.sync="selectedItem"
      v-bind="actionsProps"
      :position-x="positionX"
      :position-y="positionY"
      :value.sync="showActionsCard"
      @manageActions="manageActions" />
  </div>
</template>
<script>
import Actions from '@/components/Collections/CollectionsActions';
import CollectionsTable from '@/components/Collections/CollectionsTable';

import AppActionDotsMenu from '@/mixins/AppActionDotsMenu';
import ManageCollectionsActions from '@/mixins/ManageCollectionsActions';

export default {
  name: 'PagesTable',
  components: {
    Actions,
    CollectionsTable,
  },
  mixins: [
    AppActionDotsMenu,
    ManageCollectionsActions,
  ],
  props: {
    pagesList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      actionsProps: {
      },
    };
  },
  methods: {
    onClickActionMenu({ item, event, group = null, actionsConditions = [], showActions = false, kind = '' }) {
      this.setMenuItem({
        item,
        event,
      });
      this.actionsProps = {
        group,
        actionsConditions,
        showActions,
        kind,
      };
    },
  },
};
</script>
