<template>
  <div class="sourcery__container">
    <template v-if="getFormattedListOfPages.length">
      <div class="d-flex justify-end">
        <AppSwitcherToListing />
      </div>

      <component
        :is="isListingView ? 'PagesGallery' : 'PagesTable'"
        :pages-list="getFormattedListOfPages"
        class="mt-6" />
    </template>

    <AppEmptyList
      v-else
      from="pages" />
  </div>
</template>
<script>
import {
  mapActions,
  mapGetters,
  mapMutations,
} from 'vuex';

// components
import AppSwitcherToListing from '@/components/App/AppListingElements/AppSwitcherToListing';

import ToggleViewCondition from '@/mixins/ToggleViewCondition';

import PagesGallery from '@/views/MyPage/Views/PagesGallery.vue';
import PagesTable from '@/views/MyPage/Views/PagesTable.vue';

export default {
  name: 'MyPageView',
  components: {
    AppSwitcherToListing,
    PagesGallery,
    PagesTable,
    AppEmptyList: () => import('@/components/App/AppEmptyList'),
  },
  mixins: [ToggleViewCondition],
  computed: {
    ...mapGetters('MyPages', ['getFormattedListOfPages']),
    ...mapGetters('Workspace', ['isActiveUserGuestInWorkspace']),
    isListingView() {
      return this.toggleViewCondition('librariesRelatedPages');
    },
  },
  async created() {
    this.getListOfPages();
  },
  async updated() {
    await this.$nextTick();
    this.setUploadingSpinner(false);
  },
  methods: {
    ...mapMutations(['setUploadingSpinner']),
    ...mapActions({
      getListOfPages: 'MyPages/getListOfPages',
    }),
  },
};
</script>
